import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { AuthenticationService } from 'app/auth/service';
import {AppSearchService} from "../../lockminds/components/app-search/app-search.service";
import moment from "moment";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  private searchMode = "today";
  private startDate = moment(new Date()).format("YYYY-MM-DD")
  private endDate = moment(new Date()).format("YYYY-MM-DD")
  /**
   *
   * @param {AuthenticationService} _authenticationService
   * @param appSearch
   */
  constructor(private _authenticationService: AuthenticationService, private appSearch: AppSearchService) {
    appSearch._searchMode.subscribe( response => {
      this.searchMode = response;
    });

    appSearch._startDate.subscribe( response => {
      this.startDate = response;
    });
    appSearch._endDate.subscribe( response => {
      this.endDate = response;
    })
  }

  /**
   * Add auth header with jwt if user is logged in and request is to api url
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const currentUser = this._authenticationService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.token;
    const isApiUrl = request.url.startsWith(environment.apiUrl);

    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        headers: request.headers
            .append('Authorization', `Bearer ${currentUser.token}`)
            .append('search-mode',`${this.searchMode}`)
            .append("start-date", `${this.startDate}`)
            .append("end-date", `${this.endDate}`)
      });
    }
    return next.handle(request);

  }

}
