import { Component, OnInit } from '@angular/core';

import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';
import {MenuService} from "../../../../shared/services/menu.service";
import {CoreMenuService} from "../../../../../@core/components/core-menu/core-menu.service";
import {CoreMenuItem} from "../../../../../@core/types";

// Interface
interface notification {
  counter: number ,
  customers: number;
  sheets: number;
  orders: number;
  transactions:number
}

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
  // Public
  public notifications: notification = {
    counter: 0, customers: 0, orders: 0, sheets: 0, transactions: 0
  };

  /**
   *
   * @param {NotificationsService} _notificationsService
   * @param _menuService
   * @param _coreMenuService
   */
  constructor(private _notificationsService: NotificationsService,
              private _menuService: MenuService,
              private _coreMenuService: CoreMenuService) {
    this._menuService.onMenuItemChanged.subscribe( response => {
      if(response.length > 0){
        this._coreMenuService.unregister('updated');
        this._coreMenuService.register('updated', response);
        this._coreMenuService.setCurrentMenu('updated');
      }
    });
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

    this._notificationsService.onNotificationsChange.subscribe(res => {
      this.notifications = res;
      let menuItem = [
        {
          id : "crm",
          badge: {
            title: res.customers,
            translate: 'MENU.CM.CHARTS.BADGE',
            classes: 'badge-light-success badge-pill'
          }
        },
        {
          id : "accounting",
          badge: {
            title: res.transactions,
            translate: 'MENU.CM.CHARTS.BADGE',
            classes: 'badge-light-success badge-pill'
          }
        }
      ];

      this._menuService.updateMenus(menuItem);
    });
  }
}
