<li ngbDropdown class="nav-item dropdown-notification mr-25">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown"
    ><i class="ficon" data-feather="bell"></i
    ><span class="badge badge-pill badge-danger badge-up">{{
      notifications.counter
    }}</span></a
  >
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
  >
    <!-- Notifications header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <div class="badge badge-pill badge-light-primary">
          {{ notifications.counter}} New
        </div>
      </div>
    </li>
    <!--/ Notifications header -->

    <!-- Notifications content -->
    <li class="scrollable-container media-list" [perfectScrollbar]>

      <a class="d-flex" routerLink="/orders"  *ngIf="notifications.orders > 0">
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div
              class="avatar bg-light-success ">
              <div class="avatar-content">{{notifications.orders}}</div>
            </div>
          </div>
          <div class="media-body">
            <p class="media-heading" [innerHTML]="'New Order(s) Created'"></p>
            <small class="notification-text">You have {{ notifications.orders }} new Order(s)</small>
          </div>
        </div>
      </a>
      <a class="d-flex" routerLink="/crm/customers"  *ngIf="notifications.customers > 0">
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div
                    class="avatar bg-light-danger ">
              <div class="avatar-content">{{notifications.customers}}</div>
            </div>
          </div>
          <div class="media-body">
            <p class="media-heading" [innerHTML]="'New Customer(s) Created'"></p>
            <small class="notification-text">You have {{ notifications.customers }} new Customers(s)</small>
          </div>
        </div>
      </a>
      <a class="d-flex" routerLink="/transactions"  *ngIf="notifications.transactions > 0">
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div
                    class="avatar bg-light-warning ">
              <div class="avatar-content">{{notifications.transactions}}</div>
            </div>
          </div>
          <div class="media-body">
            <p class="media-heading" [innerHTML]="'New Transaction(s) Created'"></p>
            <small class="notification-text">You have {{ notifications.transactions }} new Transaction(s)</small>
          </div>
        </div>
      </a>
      <a class="d-flex" routerLink="/dealing-sheets"  *ngIf="notifications.sheets > 0">
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div
                    class="avatar bg-light-info ">
              <div class="avatar-content">{{notifications.sheets}}</div>
            </div>
          </div>
          <div class="media-body">
            <p class="media-heading" [innerHTML]="'New Dealing Sheets Created'"></p>
            <small class="notification-text">You have {{ notifications.sheets }} new Dealing Sheets</small>
          </div>
        </div>
      </a>
    </li>
    <!--/ Notifications content -->

    <!-- Notifications footer -->
    <li class="dropdown-menu-footer">
      <a class="btn btn-primary btn-block" href="javascript:void(0)">Read all notifications</a>
    </li>
    <!--/ Notifications footer -->
  </ul>
</li>
