import {Component, Input, OnInit, TemplateRef, ViewEncapsulation} from '@angular/core';
import {ToastService} from '../../../main/components/toasts/toasts.service';
import {HeaderService} from "./header.service";
import {FlatpickrOptions} from "ng2-flatpickr";
import moment from "moment";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AppSearchService} from "../../../lockminds/components/app-search/app-search.service";

// ContentHeader component interface
export interface ContentHeader {
  headerTitle: string;
  actionButton: boolean;
  breadcrumb?: {
    type?: string;
    links?: Array<{
      name?: string;
      isLink?: boolean;
      link?: string;
    }>;
  };
}



@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['../../../app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentHeaderComponent implements OnInit {
  // input variable
  @Input() contentHeader: ContentHeader;
  @Input() error = '';
  @Input() message = '';
  status = false;
  public toastStyle: object = {};
  @Input() progressbarHeight = "0.7px";
  alertClose = true;
  radioModel: any;

  public dateStart = "";
  public dateEnd = "";
  public dates:string = "Today";
  public previousDates:string = "Today";

  public DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    mode: 'range',
    defaultDate: new Date(),
    onChange: value => {
      let from = moment(value[0]);
      let to = moment(value[1]);
      this.dateStart = from.format("YYYY-MMM-D");
      this.dateEnd = to.format("YYYY-MMM-D");
      this.dates = `${this.dateStart} to ${this.dateEnd}`
    }
  };

  rangeValue: { from: Date; to: Date } = {
    from: new Date(),
    to: (new Date() as any)['fp_incr'](10)
  };

  constructor(private appSearch:AppSearchService, private modalService:NgbModal,public toastService: ToastService, private headerService: HeaderService) {
    headerService.onLoadingIndicatorChanges.subscribe( response => {
      this.status = response;
    })
  }


  ngOnInit() {
    this.toastStyle = { left: 'unset', right: 0 };
  }

  isTemplate(toast) {
    return toast.textOrTpl instanceof TemplateRef;
  }

  filterByRange() {
    this.previousDates = this.dates;
    this.appSearch.setSearchMode("Range")
    this.appSearch.setStartDate(this.dateStart)
    this.appSearch.setEndDate(this.dateEnd)
    this.appSearch.setReload(true)
    this.modalService.dismissAll()
  }

  showingToday(){
    this.dates = "Today";
    this.previousDates = this.dates;
    this.appSearch.setSearchMode("today")
    this.appSearch.setReload(true)
  }

  showingWeekly(){
    this.dates = "Weekly";
    this.previousDates = this.dates;
    this.appSearch.setSearchMode("Weekly")
    this.appSearch.setReload(true)
  }

  showingAnnually(){
    this.dates = "Annually";
    this.previousDates = this.dates;
    this.appSearch.setSearchMode("Annually")
    this.appSearch.setReload(true)
  }

  showingMonthly(){
    this.dates = "Monthly";
    this.previousDates = this.dates;
    this.appSearch.setSearchMode("Monthly")
    this.appSearch.setReload(true)
  }

  openModal(modal){
    this.modalService.open(modal,{
      size:"sm"
    })
  }

  cancelFilter() {
    this.dates = this.previousDates;
    this.modalService.dismissAll()
  }

}
