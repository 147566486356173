import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../../environments/environment';



@Injectable()

export class WebSocketService {

    pusher: any = "";
    chatting:any  = "";
    notifications:any  = "";
    permissions:any  = "";

    onNewEvent: BehaviorSubject<any>;

    constructor() {

        this.onNewEvent = new BehaviorSubject<any>({});

        // Enable pusher logging - don't include this in production
        Pusher.logToConsole = false;

        // @ts-ignore
        this.pusher = new Pusher('8436b0f218f49631fbde', {
            authEndpoint:environment.pusher,
            cluster: 'mt1',
            // wsHost: "173.231.197.22",
            // wsPort: 1201,
            // wssPort: 1201,
            // wsPath: "",
            // disableStats: true,
            // forceTLS: false,
            //  auth: {
            //    headers: {
            //      'X-CSRF-Token': "qCv8rV40ow9PFMhjQNqPkqSv4VuF8I3NUTjmNrSD",
            //      'X-App-ID': 1331567
            //    }
            //  },
            //  enabledTransports: ['ws']
        });

        this.chatting = this.pusher.subscribe("chatting");
        this.notifications = this.pusher.subscribe("notifications");
        this.permissions = this.pusher.subscribe("permissions");
    }


}
