import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {CoreMenuService} from "../../../@core/components/core-menu/core-menu.service";
import {AuthenticationService} from "../../auth/service";


@Injectable()

export class MenuService {

    menu: any;

    onMenuItemChanged: BehaviorSubject<any>;

    constructor(private _coreMenuService: CoreMenuService, private _authenticationService: AuthenticationService) {
        this.onMenuItemChanged = new BehaviorSubject<any>({});
        _authenticationService.permissions.subscribe(response => {
            if (response) {
                this.rebuildMenu();
            }
        })
    }

    public updateMenus(items) {
        let menu = this.configMenu(items)
        this.onMenuItemChanged.next(menu)
    }

    public configMenu(items) {
        let currentMenu = this._coreMenuService.getCurrentMenu();
        items.forEach(item => {
            currentMenu.forEach(menuItem => {
                if (menuItem.id == item.id) {
                    if(item.badge != undefined){
                        // menuItem.badge.title = item.badge.title
                        // menuItem.badge.classes = item.badge.classes
                    }
                }
            })
        })
        return currentMenu;
    }

    public rebuildMenu() {
        this.menu = this._generateMenu()
        this._coreMenuService.unregister("main")
        this._coreMenuService.unregister("updated")
        this._coreMenuService.register("updated", this.menu)
        this._coreMenuService.setCurrentMenu("updated")
    }

    _generateMenu() {
        return this._setMenus();
    }

    generatePrimaryMenu() {
        return this._setMenus();
    }
    
    _setMenus() {
        let permissions: any;
        const perms = localStorage.getItem('permissions');
        permissions = perms ? perms : [];
        return [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'item',
                icon: 'home',
                url: 'dashboard/statistics',
            },
            {
                id: 'transportation',
                title: 'Transportation',
                type: 'collapsible',
                icon: 'server',
                children: [
                    {
                        id: 'dashboard',
                        title: 'Dashboard',
                        type: 'item',
                        icon: 'circle',
                        url: 'transportation/dashboard'
                    },
                    {
                        id: 'requests',
                        title: 'Requests',
                        type: 'item',
                        icon: 'circle',
                        url: 'transportation/requests/list'
                    },
                    {
                        id: 'projects',
                        title: 'Projects',
                        type: 'item',
                        icon: 'circle',
                        url: 'transportation/projects/list'
                    },
                    {
                        id: 'settings',
                        title: 'Settings',
                        type: 'item',
                        icon: 'circle',
                        url: 'transportation/settings'
                    }
                ]
            },
            {
                id: 'freight_files',
                title: 'Freight',
                type: 'collapsible',
                icon: 'box',
                badge: {
                    title: '0',
                    translate: 'MENU.CM.CHARTS.BADGE',
                    classes: 'badge-light-success badge-pill'
                },
                children: [
                    {
                        id: 'dashboard',
                        title: 'Dashboard',
                        type: 'item',
                        icon: 'circle',
                        url: 'freight-forwarding/dashboard'
                    },
                    {
                        id: 'files',
                        title: 'Files',
                        type: 'item',
                        icon: 'circle',
                        url: 'freight-forwarding/files'
                    }, {
                        id: 'requests',
                        title: 'Requests',
                        type: 'item',
                        icon: 'circle',
                        url: 'freight-forwarding/requests/list'
                    },
                    {
                        id: 'settings',
                        title: 'Settings',
                        type: 'item',
                        icon: 'circle',
                        url: 'freight-forwarding/settings'
                    }
                ]
            },
            {
                id: 'warehouse',
                title: 'Warehouse',
                type: 'collapsible',
                icon: 'layers',
                children: [
                    {
                        id: 'dashboard',
                        title: 'Dashboard',
                        type: 'item',
                        icon: 'circle',
                        url: 'warehouse/dashboard'
                    },
                    {
                        id: 'orders',
                        title: 'Orders',
                        type: 'item',
                        icon: 'circle',
                        url: 'warehouse/orders'
                    },
                    // {
                    //     id: 'new',
                    //     title: 'New Orders',
                    //     type: 'item',
                    //     icon: 'circle',
                    //     url: 'warehouse/orders-new'
                    // },
                    {
                        id: 'picking',
                        title: 'Picking',
                        type: 'item',
                        icon: 'circle',
                        url: 'warehouse/orders-picking'
                    },
                    {
                        id: 'receiving',
                        title: 'Receive',
                        type: 'item',
                        icon: 'circle',
                        url: 'warehouse/orders-receiving'
                    },
                    {
                        id: 'inbound',
                        title: 'Inbound',
                        type: 'item',
                        icon: 'circle',
                        url: 'warehouse/orders-inbound'
                    },
                    {
                        id: 'outbound',
                        title: 'Outbound',
                        type: 'item',
                        icon: 'circle',
                        url: 'warehouse/orders-outbound'
                    }
                ]
            },
            {
                id: 'courier',
                title: 'Courier',
                type: 'collapsible',
                icon: 'package',
                badge: {
                    title: '0',
                    translate: 'MENU.CM.CHARTS.BADGE',
                    classes: 'badge-light-success badge-pill'
                },
                children: [
                    {
                        id: 'dashboard',
                        title: 'Dashboard',
                        type: 'item',
                        icon: 'circle',
                        url: 'courier/dashboard'
                    }
                    ,
                    {
                        id: 'requests',
                        title: 'Requests',
                        type: 'item',
                        icon: 'circle',
                        url: 'courier/requests'
                    },
                    {
                        id: 'dn',
                        title: 'Delivery Notes',
                        type: 'item',
                        icon: 'circle',
                        url: 'courier/delivery-notes/list'
                    },
                ]
            },
            {
                id: 'crm',
                title: 'CRM',
                type: 'collapsible',
                icon: 'box',
                hidden: !permissions.includes("customers_read") &&
                    !permissions.includes("customer_categories_read") &&
                    !permissions.includes("news_letter_categories_read"),
                children: [
                    {
                        id: 'customers',
                        title: 'Customers',
                        type: 'item',
                        icon: 'circle',
                        url: 'crm/customers'
                    },
                    {
                        id: 'customer-categories',
                        title: 'Categories',
                        type: 'item',
                        icon: 'circle',
                        url: 'crm/customers/categories/list',
                        hidden: !permissions.includes("customer_categories_read")
                    }
                ]
            },
            {
                id: 'accounting',
                title: 'Accounting System',
                type: 'collapsible',
                icon: 'layers',
                hidden: !permissions.includes("general_ledger_read")
                    && !permissions.includes("trial_balance_read")
                    && !permissions.includes("balance_sheet_read")
                    && !permissions.includes("income_statement_read")
                    && !permissions.includes("chat_of_accounts_read")
                    && !permissions.includes("receipts_read")
                    && !permissions.includes("vouchers_read")
                    && !permissions.includes("journal_read")
                    && !permissions.includes("payment_read")
                    && !permissions.includes("reconciliation_read")
                    && !permissions.includes("chat_of_accounts_read")
                    && !permissions.includes("account_settings"),
                children: [
                    {
                        id: 'transactions',
                        title: 'Transactions',
                        type: 'item',
                        icon: 'circle',
                        url: 'accounting/transactions/list',
                        hidden: !permissions.includes("general_ledger_read")
                    },{
                        id: 'payments',
                        title: 'Payments',
                        type: 'item',
                        icon: 'circle',
                        url: 'accounting/payments',
                        hidden: !permissions.includes("payment_read")
                    },
                    {
                        id: 'receipts',
                        title: 'Receipts',
                        type: 'item',
                        icon: 'circle',
                        url: 'accounting/receipts',
                        hidden: !permissions.includes("receipts_read")
                    },
                    {
                        id: 'journal',
                        title: 'Journals',
                        type: 'item',
                        icon: 'circle',
                        url: 'accounting/journals' ,
                        hidden: !permissions.includes("journal_read")
                    },
                    {
                        id: 'chart-accounts',
                        title: 'Chart of Accounts',
                        type: 'item',
                        icon: 'circle',
                        url: 'accounting/accounts/chart-of-accounts',
                        hidden: !permissions.includes("chat_of_accounts_read")
                    },
                    {
                        id: 'reports',
                        title: 'Reports',
                        type: 'item',
                        icon: 'circle',
                        url: 'accounting/reports',
                        hidden: !permissions.includes("balance_sheet_read")
                            && !permissions.includes("trial_balance_read")
                            && !permissions.includes("income_statement_read")
                    },
                    {
                        id: 'gl',
                        title: 'General Ledger',
                        type: 'item',
                        icon: 'circle',
                        url: 'accounting/general-ledger',
                        hidden: !permissions.includes("general_ledger_read")
                    },
                    {
                        id: 'settings',
                        title: 'Settings',
                        type: 'item',
                        icon: 'circle',
                        url: 'accounting/settings',
                        hidden: !permissions.includes("account_settings")
                    }
                ]
            },
            {
                id: 'hr',
                title: 'Human Resource',
                type: 'collapsible',
                icon: 'users',
                hidden: !permissions.includes("employees_read")
                    && !permissions.includes("departments_read"),
                children: [
                    {
                        id: 'list',
                        title: 'Employees',
                        type: 'item',
                        icon: 'circle',
                        url: 'hr/employees/list',
                        hidden: !permissions.includes("employees_read")
                    },
                    {
                        id: 'deliverers',
                        title: 'Deliverers',
                        type: 'item',
                        icon: 'circle',
                        url: 'hr/deliverers/list',
                        hidden: !permissions.includes("employees_read")
                    },
                    {
                        id: 'department',
                        title: 'Departments',
                        type: 'item',
                        icon: 'circle',
                        url: 'hr/departments/list',
                        hidden: !permissions.includes("departments_read")
                    }
                ]
            },
            {
                id: 'truck-requests',
                title: 'Truck Requests',
                type: 'item',
                icon: 'home',
                badge: {
                    title: '0',
                    translate: 'MENU.CM.CHARTS.BADGE',
                    classes: 'badge-light-success badge-pill'
                },
                url: 'truck-requests/list',
            },
            {
                id: 'file-manager',
                title: 'File Manager',
                type: 'item',
                icon: 'folder',
                url: 'apps/file-manager/files',
            },
            {
                // If role is not assigned will be display to all
                id: 'messaging',
                title: 'Messaging',
                type: 'item',
                icon: 'message-circle',
                url: 'apps/messaging/messages'
            },
            {
                id: 'settings',
                type: 'section',
                title: 'Settings',
                icon: 'box',
            },
            {
                id: 'business',
                title: 'Business',
                type: 'item',
                icon: 'briefcase',
                url: 'settings/business'
            },
            {
                // If role is not assigned will be display to all
                id: 'roles',
                title: 'Roles',
                type: 'item',
                icon: 'grid',
                url: 'settings/roles'
            }
        ];
    }


}
