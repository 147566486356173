<!-- app-content-header start -->
<ng-container >
  <div class="content-header row">
    <div class="content-header-left col-md-8 mb-2">
      <div class="row breadcrumbs-top">
        <div class="col-12 d-flex">
          <!-- app-breadcrumb component -->
          <app-breadcrumb [title]="contentHeader?.headerTitle" [breadcrumb]="contentHeader?.breadcrumb"></app-breadcrumb><br/>
        </div>
      </div>
    </div>

    <div class="content-header-right col-md-4 mb-2">
      <div class="btn-group btn-group-toggle" ngbRadioGroup >
        <label ngbButtonLabel class="btn-outline-primary" rippleEffect
        ><input ngbButton (click)="showingToday()" type="radio" [value]="'today'" />Today </label>
        <label ngbButtonLabel class="btn-outline-primary" rippleEffect
        ><input ngbButton (click)="showingWeekly()" type="radio" [value]="'weekly'" />Weekly </label>
        <label ngbButtonLabel class="btn-outline-primary" rippleEffect
        ><input ngbButton (click)="showingMonthly()" type="radio" value="middle" />Monthly </label>
        <label ngbButtonLabel class="btn-outline-primary" rippleEffect
        ><input ngbButton (click)="showingAnnually()" type="radio" [value]="false" />Annually
        </label>
        <button (click)="openModal(dateModal)" type="button" class="btn btn-icon btn-outline-primary" rippleEffect>
          <span [data-feather]="'calendar'"></span></button>
      </div>
      <br/>
      <b  *ngIf="dates != ''">Showing {{dates}}</b><br/>
    </div>
  </div>
</ng-container>
<!-- app-content-header end -->

<ng-template #dateModal let-modal >
  <div class="modal-content"  >
    <div class="modal-header bg-transparent">
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <div class="text-center mb-4">
        <h3 class="card-title">Date Range</h3>
      </div>
      <div class="row">
        <div class="form-group col-12">
          <ng2-flatpickr [(ngModel)]="rangeValue" [config]="DateRangeOptions" name="DateRange"></ng2-flatpickr>
        </div>
        <div class="form-group col-md-6">
          <button (click)="cancelFilter()" type="button" class="btn btn-block btn-warning" rippleEffect>Cancel</button>
        </div>
        <div class="form-group col-md-6">
          <button (click)="filterByRange()" type="button" class="btn btn-block btn-primary" rippleEffect>Filter</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
