import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    //public
    public currentUser: Observable<User>;
    public settingsChanged: BehaviorSubject<any>;

    //private
    private currentUserSubject: BehaviorSubject<User>;
    permissions: BehaviorSubject<any>;

    /**
     *
     * @param {HttpClient} _http
     * @param {ToastrService} _toastrService
     */
    constructor(private _http: HttpClient, private _toastrService: ToastrService) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.settingsChanged = new BehaviorSubject<any>({});
        this.permissions = new BehaviorSubject<any>({});
    }

    // getter: currentUserValue
    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }


    /**
     * User login
     *
     * @param email
     * @param password
     * @returns user
     */
    login(email: string, password: string) {
        return this._http
            .post<any>(`${environment.apiUrl}/login`, { email, password })
            .pipe(
                map(user => {
                    // login successful if there's a jwt token in the response
                    if (user && user.token) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('currentUser', JSON.stringify(user));
                        localStorage.setItem('notifications', JSON.stringify(user.notifications));
                        localStorage.setItem('profilePicture', user.avatar);
                        localStorage.setItem('permissions', JSON.stringify(user.permissions));
                        this.currentUserSubject.next(user);
                    }

                    return user;
                })
            );
    }

    /**
     * User logout
     *
     */
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('config');
        localStorage.removeItem('notifications');
        localStorage.removeItem('prevSkin');
        localStorage.removeItem('profilePicture');
        localStorage.removeItem('pusherTransportTLS');
        // notify
        this.currentUserSubject.next(null);
    }

    register(formData: FormData) {
        return this._http
            .post<any>(`${environment.apiUrl}/register`, formData)
            .pipe(
                map(user => {
                    // login successful if there's a jwt token in the response
                    if (user && user.token) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('currentUser', JSON.stringify(user));
                        localStorage.setItem('profilePicture', user.avatar);
                        localStorage.setItem('notifications', JSON.stringify(user.notifications));
                        localStorage.setItem('permissions', JSON.stringify(user.permissions));
                        this.currentUserSubject.next(user);
                    }

                    return user;
                })
            );
    }

    public updatePermissions(){
        const currentUser = localStorage.getItem("currentUser");
        if(currentUser != "" && currentUser != undefined){
            this._http.get(`${environment.apiUrl}/permissions`).subscribe( (response:any) => {
                localStorage.setItem('permissions', JSON.stringify(response));
                this.permissions.next( JSON.stringify(response))
            })
        }
    }

    confirmVerification(formData: FormData) {
        return this._http
            .post<any>(`${environment.apiUrl}/verify-account`, formData)
            .pipe(
                map(response => {

                })
            );
    }

    sendPasswordReset(formData: FormData) {
        return this._http
            .post<any>(`${environment.apiUrl}/send-password-reset`, formData)
            .pipe(
                map(response => {

                })
            );
    }

    changePassword(formData: FormData) {
        return this._http
            .post<any>(`${environment.apiUrl}/password-reset`, formData)
            .pipe(
                map(response => {

                })
            );
    }

    getSettings(){
        this._http.get(`${environment.apiUrl}/auth/settings`).subscribe((response: any) => {
            this.settingsChanged.next(response);
        });
    }
}
