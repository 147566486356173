import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class AppSearchService {

  _searchMode:BehaviorSubject<any>;
  _startDate: BehaviorSubject<any>;
  _endDate: BehaviorSubject<any>;
  _reload: BehaviorSubject<any>;

  constructor() {
    this._searchMode = new BehaviorSubject<any>({})
    this._reload = new BehaviorSubject<any>({})
    this._endDate = new BehaviorSubject<any>({});
    this._startDate = new BehaviorSubject<any>({});
    this._searchMode.next("today");
    this._endDate.next(moment(new Date()).format("YYYY-MM-DD"))
    this._startDate.next(moment(new Date()).format("YYYY-MM-DD"))
  }

  setSearchMode(value: any) {
    this._searchMode.next(value);
  }

  setStartDate(value: any) {
    this._startDate.next(value);
  }

  setEndDate(value: any) {
    this._endDate.next(value);
  }

  setReload(value: any) {
    this._reload.next(value);
  }

}
