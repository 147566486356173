import {HttpClient, HttpEvent, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from "../../../../environments/environment";

@Injectable()
export class HeaderService  {
  onLoadingIndicatorChanges: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    // Set the defaults
    this.onLoadingIndicatorChanges = new BehaviorSubject<any>({});
    this.onLoadingIndicatorChanges = new BehaviorSubject<any>({});
  }

  stopLoading(){
      this.onLoadingIndicatorChanges.next(false)
  }

  startLoading(){
    this.onLoadingIndicatorChanges.next(true)
  }

}
