import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreModule} from '@core/core.module';
import {CoreCommonModule} from '@core/common.module';
import {CoreSidebarModule, CoreThemeCustomizerModule} from '@core/components';
import {coreConfig} from 'app/app-config';
import {AuthGuard} from 'app/auth/helpers/auth.guards';
import {ErrorInterceptor, JwtInterceptor} from 'app/auth/helpers';
import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {ContentHeaderModule} from 'app/layout/components/content-header/content-header.module'
import {WebSocketService} from './shared/services/web-socket.service';
import {NotificationsService} from './layout/components/navbar/navbar-notification/notifications.service';
import {MenuService} from './shared/services/menu.service';
import {AppSearchService} from "./lockminds/components/app-search/app-search.service";
import {ToastrModule} from "ngx-toastr";
import {TranslateModule} from "@ngx-translate/core";

const appRoutes: Routes = [

    {
        path: '',
        loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
    },
    {
      path: 'truck-requests',
      loadChildren: () => import('./main/truck-requests/truck-requests.module').then(m => m.TruckRequestsModule),
      canActivate: [AuthGuard]
    },
    {
        path: 'apps',
        loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'warehouse',
        loadChildren: () => import('./main/warehouse/warehouse.module').then(m => m.WarehouseModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'courier',
        loadChildren: () => import('./main/courier/courier.module').then(m => m.CourierModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'transportation',
        loadChildren: () => import('./main/transportation/transportation.module').then(m => m.TransportationModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'freight-forwarding',
        loadChildren: () => import('./main/freight/freight.module').then(m => m.FreightModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'hr',
        loadChildren: () => import('./main/hr/hr.module').then(m => m.HrModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'profile',
        loadChildren: () => import('./main/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'settings',
        loadChildren: () => import('./main/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'auth',
        loadChildren: () => import('./main/authentication/authentication.module').then(m => m.AuthenticationModule)
    },

    {
        path: 'accounting',
        loadChildren: () => import('./main/accounting/accounting.module').then(m => m.AccountingModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'crm',
        loadChildren: () => import('./main/crm/crm.module').then(m => m.CrmModule),
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {
            scrollPositionRestoration: 'enabled', // Add options right here
            relativeLinkResolution: 'legacy'
        }),
        NgbModule,
        CoreModule.forRoot(coreConfig),
        CoreCommonModule,
        CoreSidebarModule,
        CoreThemeCustomizerModule,
        LayoutModule,
        ContentHeaderModule,
        ToastrModule.forRoot(),
        TranslateModule.forRoot()
    ],
    exports: [],
    providers: [
        AppSearchService,
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        WebSocketService,
        NotificationsService,
        MenuService,
    ],
    entryComponents: [],
    bootstrap: [AppComponent]
})

export class AppModule {}
